// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fantasiakappaleita-lapsille-js": () => import("./../src/pages/fantasiakappaleita-lapsille.js" /* webpackChunkName: "component---src-pages-fantasiakappaleita-lapsille-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kahdeksan-vuodenaikaa-js": () => import("./../src/pages/kahdeksan-vuodenaikaa.js" /* webpackChunkName: "component---src-pages-kahdeksan-vuodenaikaa-js" */),
  "component---src-pages-maiju-js": () => import("./../src/pages/maiju.js" /* webpackChunkName: "component---src-pages-maiju-js" */)
}

