const baseHeading = {
  fontFamily: "heading",
  fontWeight: 700,
  lineHeight: "heading",
  color: "text",
  letterSpacing: "heading",
  mt: 0,
  mb: [3, 4],
}

const baseButton = {
  display: "inline-block",
  fontFamily: "heading",
  fontWeight: "bold",
  fontSize: [2, 18],
  borderRadius: "99px",
}

export default {
  breakpoints: ["640px", "960px", "1280px"],
  colors: {
    text: "#003952",
    background: "#fff",
    pink: "#D52D62",
    gray: {
      100: "#f5f5f5",
      200: "#eaeaea",
      300: "#e1e1e1",
    },
  },
  fonts: {
    body: "system-ui, sans-serif",
    heading: "futura-pt, system-ui, sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },
  lineHeights: {
    heading: 1.125,
    body: 1.5,
  },
  layout: {
    container: {
      maxWidth: 1440,
    },
    narrow: {
      maxWidth: 768,
    },
  },
  links: {
    text: {
      color: "text",
    },
  },
  letterSpacing: {
    heading: "-.025em",
  },
  links: {
    text: {
      color: "text",
      textDecoration: "none",
    },
  },
  buttons: {
    primary: {
      bg: "pink",
      color: "white",
      ...baseButton,
    },
    default: {
      bg: "gray.300",
      color: "text",
      ...baseButton,
    },
  },
  styles: {
    root: {
      position: "relative",
      fontFamily: "body",
      lineHeight: "body",
      WebkitFontSmoothing: "antialiased",
    },
    a: {
      position: "relative",
      color: "pink",
      textDecoration: "none",
      transition: "all .2s ease",
      fontWeight: 500,
      //boxShadow: "inset 0 -1px 0 #D52D62",
      /*
      ":hover": {
        boxShadow: "inset 0 -2px 0 #D52D62",
      },*/
    },
    strong: {
      fontWeight: 500,
    },
    h1: {
      ...baseHeading,
      fontSize: ["9vw", 5, 6, 7],
    },
    h2: {
      ...baseHeading,
      fontSize: [4, 5, 6],
    },
    p: {
      mt: 0,
      mb: "1em",
    },
  },
}
